import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  catchError,
  Observable,
  Subscription,
  tap,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAuthFeatureState } from '../_store/auth/auth.states';
import { AppState, ILogin, IToken } from '../shared/interfaces/utils.interface';
import {
  loginSuccess,
  logoutFailure,
  logoutSuccess,
  storedAuth,
} from '../_store/auth/auth.action';
import { selectGetToken } from '../_store/auth/auth.selectors';
import { setUserInfo } from '../_store/user/user.actions';
import { GoogleAuthService } from './google-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiUrl; // Replace with your actual API URL
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  token!: IToken;
  constructor(
    private http: HttpClient,
    private route: Router,
    private store: Store<AppState>,
    private googleAuthService: GoogleAuthService
  ) {}

  login(login: ILogin) {
    return this.http.post<any>(`${this.apiUrl}auth/signin`, login).pipe(
      // tap((user) => {
      //   // store user details and jwt token in local storage
      //   const { data } = user;
      //   localStorage.setItem('auth', JSON.stringify(data));
      // }),
      tap(() => this.googleAuthService.restoreSession()),
      catchError(this.handleError)
    );
  }

  logout(isAdmin?: boolean) {
    // remove user from local storage to log user out
    this.http
      .get<any>(`${this.apiUrl}auth/logout`)
      .pipe(catchError(this.handleError))
      .subscribe({
        next: (data) => {
          this.store.dispatch(logoutSuccess());
          this.store.dispatch(setUserInfo({ user: null }));
          this.googleAuthService.logoutGapi();
        },
        error: (err) => this.store.dispatch(logoutFailure(err)),
        complete: () => this.route.navigate(['']),
      });
  }

  forgotPassword(body: { email: string }) {
    return this.http
      .post<any>(`${this.apiUrl}auth/forgotPassword`, body)
      .pipe(catchError(this.handleError));
  }
  resetPassword(body: { password: string }, token: string) {
    let params = new HttpParams();
    params = params.set('token', token);
    const requestOptions = {
      params: params,
    };
    return this.http
      .post<any>(`${this.apiUrl}auth/reset/password`, body, requestOptions)
      .pipe(catchError(this.handleError));
  }

  // Function to notify subscribers when the refresh token is updated
  public notifyRefreshTokenUpdated(token: string | null): void {
    this.refreshTokenSubject.next(token);
  }

  // Getter for the observable to subscribe to refresh token updates
  public get refreshTokenObservable(): Observable<any> {
    return this.refreshTokenSubject.asObservable();
  }

  RefreshToken(): Observable<any> {
    const currentUser = localStorage.getItem('auth');
    let refreshToken;
    if (currentUser) {
      const parseData = JSON.parse(currentUser);
      refreshToken = parseData.refreshToken ? parseData.refreshToken : null;
    } else {
      refreshToken = null;
    }

    let header = new HttpHeaders({
      Authorization: 'Bearer ' + refreshToken,
    });
    return this.http.get<any>(`${this.apiUrl}auth/refresh`, {
      headers: header,
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      console.error('service::error ', error.error);
    }

    return throwError(() => error.error);
  }
}
