import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
declare const google: any; // Declare Google Identity Services globally
declare const gapi: any;
export interface IGoogleAuth {
  accessToken: string;
  user: string;
}
@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {
  private clientId = environment.GOOGLE_clientId;
  private apiKey = environment.GOOGLE_apiKey;
  private scope =
    'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';
  private tokenClient: any;

  private email: string = '';

  private savedUser: IGoogleAuth = {
    accessToken: '',
    user: '',
  };

  constructor() {
    this.loadGoogleAPIs();
  }

  private loadGoogleAPIs(): void {
    const policy = (window as any).trustedTypes?.createPolicy(
      'google-scripts',
      {
        createScriptURL: (input: string) => input,
        createScript: (input: string) => input,
        createHTML: (input: string) => input,
      }
    );

    // Load Google Identity Services
    if (!(window as any).google) {
      const scriptGIS = document.createElement('script');
      scriptGIS.src =
        policy?.createScriptURL('https://accounts.google.com/gsi/client') ??
        'https://accounts.google.com/gsi/client';
      document.body.appendChild(scriptGIS);
    }

    // Load Google API Client
    if (!window.gapi) {
      const scriptGAPI = document.createElement('script');
      scriptGAPI.src =
        policy?.createScriptURL('https://apis.google.com/js/api.js') ??
        'https://apis.google.com/js/api.js';

      scriptGAPI.onload = () => this.initGapi();
      document.body.appendChild(scriptGAPI);
    } else {
      this.initGapi();
    }
  }

  // private loadGoogleAPIs(): void {
  //   // Load Google Identity Services
  //   if (!(window as any).google) {
  //     const scriptGIS = document.createElement('script');
  //     scriptGIS.src = 'https://accounts.google.com/gsi/client';
  //     document.body.appendChild(scriptGIS);
  //   }
  //   // Load Google API Client
  //   if (!window.gapi) {
  //     const scriptGAPI = document.createElement('script');
  //     scriptGAPI.src = 'https://apis.google.com/js/api.js';
  //     scriptGAPI.onload = () => this.initGapi();
  //     document.body.appendChild(scriptGAPI);
  //   } else {
  //     this.initGapi();
  //   }
  // }

  private initGapi(): void {
    gapi.load('client', async () => {
      await gapi.client
        .init({
          apiKey: this.apiKey,
          discoveryDocs: [
            'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
            'https://www.googleapis.com/discovery/v1/apis/people/v1/rest',
          ],
        })
        .then((res: any) => console.log('Google API initialized'))
        .catch((err: any) => console.log('Google API Error', err));
      this.tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: this.clientId,
        scope: this.scope,
        callback: (response: any) => this.handleTokenResponse(response),
      });

      this.restoreSession();
    });
  }

  private handleTokenResponse(response: any) {
    if (response.access_token) {
      this.savedUser.accessToken = response.access_token;
      gapi.client.setToken({ access_token: this.savedUser.accessToken });
      localStorage.setItem('google_auth', JSON.stringify(this.savedUser));
    } else {
      console.error('Failed to obtain access token:', response);
    }
  }

  restoreSession(): void {
    const user = localStorage.getItem('google_auth');
    if (user) {
      const parseSavedUser: IGoogleAuth = JSON.parse(user);
      this.savedUser = parseSavedUser;

      gapi.client.setToken({ access_token: this.savedUser.accessToken });
    }
  }

  signIn(): Promise<string | void> {
    return new Promise((resolve, reject) => {
      this.tokenClient.requestAccessToken();
      this.tokenClient.callback = async (response: any) => {
        if (response.access_token) {
          this.savedUser.accessToken = response.access_token;
          gapi.client.setToken({ access_token: this.savedUser.accessToken });

          const user = await this.getUser(this.savedUser.accessToken);
          this.savedUser.user = user.name;
          localStorage.setItem('google_auth', JSON.stringify(this.savedUser));
          resolve(this.savedUser.user);
        } else {
          reject('Failed to authenticate');
        }
      };
    });
  }

  silentSignIn(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.savedUser.accessToken) {
        console.log('Using stored token');
        // gapi.client.setToken({ access_token: this.savedUser.accessToken });
        resolve();
      } else {
        reject('Failed to authenticate');
      }
    });
  }

  async getUser(token: string) {
    // Fetch user profile using Google People API
    try {
      const response = await fetch(
        'https://www.googleapis.com/oauth2/v1/userinfo?alt=json',
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch user info');
      }

      const userInfo = await response.json();

      return userInfo; // ✅ Return the full user object
    } catch (err) {
      console.log('Error fetching user info:', err);
      return null; // Return null if there's an error
    }
  }

  async ensureAuthenticated() {
    try {
      await this.silentSignIn();
    } catch {
      await this.signIn();
    }
  }

  logout(): void {
    this.savedUser.user = '';
    this.savedUser.accessToken = '';
    localStorage.removeItem('google_auth');
    gapi.client.setToken(null);
  }
  logoutGapi(): void {
    gapi.client.setToken(null);
  }
}
