import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IUserFeatureState, userFeatureKey } from './user.states';

const selectUserFeature =
  createFeatureSelector<IUserFeatureState>(userFeatureKey);

//get user
export const selectUser = createSelector(
  selectUserFeature,
  (state: IUserFeatureState) => state.profile
);
//get user
export const selectRole = createSelector(
  selectUserFeature,
  (state: IUserFeatureState) => (state.profile ? state.profile.roles : null)
);
//get isPrivateMerch
export const selectIsPrivateMerch = createSelector(
  selectUserFeature,
  (state: IUserFeatureState) =>
    state.profile?.merchant ? state.profile.merchant.isPrivate : null
);

//get user id
export const selectUserID = createSelector(
  selectUserFeature,
  (state: IUserFeatureState) => state.profile?._id
);
//get merchant id
export const selectMerchantID = createSelector(
  selectUserFeature,
  (state: IUserFeatureState) => state.profile?.merchant?._id
);
//get load user success
export const selectUserSuccess = createSelector(
  selectUserFeature,
  (state: IUserFeatureState) => state.isSuccess
);
