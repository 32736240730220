import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { notiReducer } from './_store/noti/noti.reducer';
import { userReducer } from './_store/user/user.reducer';
import { UserEffects } from './_store/user/user.effects';
import { AuthReducer } from './_store/auth/auth.reducer';
import { AuthEffects } from './_store/auth/auth.effects';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './services/token.interceptor';
import { PermissionsService } from './services/auth.guard';
import { NotFoundComponent } from './not-found/not-found.component';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';

const dbConfig: DBConfig = {
  name: 'MyDb',
  version: 1,
  objectStoresMeta: [
    {
      store: 'queries',
      storeConfig: { keyPath: 'id', autoIncrement: false }, // ใช้ id เป็น key หลัก
      storeSchema: [
        {
          name: 'queryParams',
          keypath: 'queryParams',
          options: { unique: false },
        },
      ],
    },
  ],
};

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    HttpClientModule,
    StoreModule.forRoot({
      noti: notiReducer,
      user: userReducer,
      auth: AuthReducer,
    }),
    EffectsModule.forRoot([UserEffects, AuthEffects]),
    NgxIndexedDBModule.forRoot(dbConfig),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    PermissionsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
